import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import withFieldValidation from '../../components/hoc/with-field-validation'

const nameInput = (props) => {
  const {
    fieldValid, onValueInput, name, touched, validateHandler,
  } = props

  const onChangeHandler = (e) => {
    onValueInput(e)
    validateHandler(e.target.value)
  }

  const showValid = touched && fieldValid

  return (
    <Fragment>
      <label htmlFor="name">
        Report name
      </label>
      <Input
        type="text"
        name="name"
        placeholder="Provide a descriptive name for this report"
        value={name}
        valid={showValid}
        invalid={!fieldValid}
        onChange={e => onChangeHandler(e)}
      />
    </Fragment>
  )
}

nameInput.propTypes = {
  fieldValid: PropTypes.bool.isRequired,
  onValueInput: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  validateHandler: PropTypes.func.isRequired,
}

export default withFieldValidation(nameInput)
