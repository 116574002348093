import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const PercentFormatter = ({ value }) => (
  value === null || value === undefined ? <span> </span> : <span>{`${value.toFixed(2)}%`}</span>
)

const PercentTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={PercentFormatter}
    {...props}
  />
)

export default PercentTypeProvider
