import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const booleanFormatter = ({ column, row, value }) => {
  return value ? (<span className="oi oi-check" />) : (<span className="oi oi-x" />)
}

const BooleanTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={booleanFormatter}
    {...props}
  />
)

export default BooleanTypeProvider
