import PropTypes from 'prop-types'
import React from 'react'
import { Button, Col, FormGroup } from 'reactstrap'

const buttonBar = (props) => {
  const {
    onSaveReport, valid,
  } = props
  return (
    <FormGroup row>
      <Col sm={12}>
        <Button
          disabled={!valid}
          onClick={() => onSaveReport()}
        >
          Save Report
        </Button>
      </Col>
    </FormGroup>
  )
}

buttonBar.propTypes = {
  onSaveReport: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
}

export default buttonBar
