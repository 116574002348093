const customerType = args => (args.customerType)

const depositAmount = args => (args.depositAmount && `$${parseInt(args.depositAmount / 1000)}k`)

const termDepositFilterSummary = args => (
  [
    customerType(args),
    depositAmount(args),
  ].filter(Boolean).join(' | ')
)

export default termDepositFilterSummary
