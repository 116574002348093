import React from 'react'
import withReportEdit from '../../components/hoc/with-report-edit'
import NameInput from '../components/name-input'
import SharedInput from '../components/shared-input'
import EndpointInput from '../components/endpoint-input'
import ArgumentOptions from '../components/argument-options'
import FieldsInput from '../components/fields-input'
import LockedFieldInput from '../components/locked-field-input'
import FlashBar from '../components/flash-bar'
import ButtonBar from '../components/button-bar'

const reportForm = props => (

  <form className="b-create-report__form">
    <h3>Report details</h3>

    <fieldset className="b-create-report__form-fieldset">
      <div className="row">
        <div className="col-12 col-md-6">
          <NameInput {...props} />
        </div>
        <div className="col-12 col-md-6">
          <EndpointInput {...props} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <FieldsInput {...props} />
        </div>
        <div className="col-12 col-md-6">
          <LockedFieldInput {...props} />
        </div>
      </div>
      <SharedInput {...props} />
    </fieldset>

    <h3>Report filters</h3>

    <fieldset className="b-create-report__form-fieldset">
      <ArgumentOptions {...props} />
    </fieldset>

    <ButtonBar {...props} />
    <FlashBar {...props} />
  </form>
  )

export default withReportEdit(reportForm)
