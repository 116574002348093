import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import {
  Button,
  Col,
  Input,
  Row,
} from 'reactstrap'
import ArgumentInput from '../../reports/containers/argument-input'
import FixedColumnButton from '../components/fixed-column-button'
import PrintButton from '../components/print-button'
import ToggleToolbar from '../../reports/components/toggle-toolbar'
import ToolbarLinkButton from '../components/toolbar-link-button'
import filterSummary from '../../utils/filter-summary/filter-summary'

class ReportUpdater extends Component {
  constructor(props) {
    super(props)
    const { args } = props
    this.state = {
      filterOpen: false,
      inputs: args,
    }
  }

  onArgumentInput = (name, data) => {
    const { inputs } = this.state
    const { setReportArguments } = this.props

    if (Array.isArray(data)) { // multi select. get values correctly
      if (data.length > 0) {
        inputs[name] = data.map(entry => (entry.value))
      } else {
        delete inputs[name]
      }
    } else {
      const target = data && data.target ? data.target : data
      if (target) {
        inputs[name] = target.value
      } else {
        delete inputs[name]
      }
    }

    this.setState({ inputs })
    setReportArguments(inputs)
  }

  render() {
    const {
      args,
      csvUrl,
      csvUrlWithNumeric,
      endpoint,
      inputs,
      onSearchChange,
      reloadData,
    } = this.props

    const hasInputs = Object.keys(inputs).length > 0
    const { filterOpen } = this.state
    const isExpanded = filterOpen ? ' is-expanded' : ''

    const filterToggle = () => {
      this.setState({
        filterOpen: !filterOpen,
      })
    }

    const rows = inputs.map(input => (
      <Col sm={3} key={input.name}>
        <ArgumentInput
          {...this.props}
          name={input.name}
          defaultValue={input.defaultValue}
          description={input.description}
          includeTooltip={false}
          options={input.options}
          onValueChange={this.onArgumentInput}
          type={input.type}
        />
      </Col>
    ))

    const button = (
      <Col sm={3}>
        <Button
          type="submit"
          className="btn btn-primary btn-block"
          onClick={() => reloadData(inputs)}
        >
          Refresh
        </Button>
      </Col>
    )

    const search = (
      <div className="b-toolbar__actions">
        <PrintButton {...this.props} />
        <ToggleToolbar
          {...this.props}
          name="show-hide-toolbar"
        />
        <ToolbarLinkButton
          {...this.props}
          href={csvUrl}
        />
        <ToolbarLinkButton
          {...this.props}
          href={csvUrlWithNumeric}
          icon="fas fa-sort-numeric-down-alt"
          id="download-csv-with-numeric"
          tooltip="Download CSV with rates as decimals"
        />
        <FixedColumnButton {...this.props} />
        <div className="row">
          <div className="col-auto">
            <label className="sr-only" htmlFor="report-filter-search">
              Search
            </label>
            <div className="b-toolbar__search">
              <Input
                type="search"
                name="search"
                placeholder="Search"
                onChange={e => onSearchChange(e.currentTarget.value)}
              />
              <button type="button" className="btn b-toolbar__search-icon">
                <span className="fas fa-search" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )

    const filter = hasInputs && (
      <div id="report-filter" className={`b-report-filter${isExpanded}`}>
        <button
          type="button"
          id="report-filter-toggle"
          className="btn btn-light b-report-filter__toggle"
          onClick={() => filterToggle()}
        >
          <span className="fa fa-sliders-h" />
          {' '}
          Filters
        </button>
        <div className="b-report-filter__dropdown">
          <Row>
            {rows}
          </Row>
          <Row className="align-middle">
            {button}
          </Row>
        </div>
      </div>
    )

    const view = (
      <Fragment>
        <h6 className="b-main__header-subtitle">
          {filterSummary(args, endpoint)}
        </h6>
        <div className="b-toolbar">
          {search}
          {filter}
        </div>
      </Fragment>
    )

    return view
  }
}

ReportUpdater.propTypes = {
  args: PropTypes.shape({}).isRequired,
  csvUrl: PropTypes.string.isRequired,
  csvUrlWithNumeric: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(PropTypes.shape({})),
  onSearchChange: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  setReportArguments: PropTypes.func.isRequired,
  selectedEndpoint: PropTypes.string,
}

ReportUpdater.defaultProps = {
  selectedEndpoint: null,
  inputs: null,
}

export default ReportUpdater