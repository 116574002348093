import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import ReportButton from '../../utils/ui/report-button'
import AlertModal from '../components/alert-modal'
import { withToggle } from '../../components/hoc/index'


class CopyReportButton extends Component {
  state={
    errors: [],
  }

  copyReport = () => {
    const { id } = this.props
    const endpoint = `${window.location}/${id}/copy`
    axios.post(endpoint)
    .then((response) => {
      if (response.data.success) {
        window.location.reload()
      } else {
        this.setState({
          errors: response.data.error,
        })
      }
    })
    .catch((e) => {
      console.log(e)
    })
  }

  render() {
    const { errors } = this.state
    const showErrors = errors && errors.length > 0

    const alert = showErrors && (
      <AlertModal
        {...this.props}
        body={errors[0]}
      />
    )

    return (
      <Fragment>
        <ReportButton
          color="info"
          title="Copy the report"
          icon="oi oi-layers"
          onClick={this.copyReport}
        />
        {alert}
      </Fragment>
    )
  }
}

CopyReportButton.propTypes = {
  id: PropTypes.number.isRequired,
}
export default withToggle(CopyReportButton)
