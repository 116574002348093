import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from './form'

const EmailScheduleTool = (props) => {
  const MySwal = withReactContent(Swal)
  const [fieldValues, setFieldValues] = useState({
    beginAt: new Date(),
    frequency: '',
    subject: '',
  })

  const [csrfToken, setCsrfToken] = useState('')
  const [frequencies, setFrequencies] = useState([])
  const [subscriberId, setSubscriberId] = useState('')
  const [reports, setReports] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [emailScheduleId, setEmailScheduleId] = useState('')
  const [selectedReports, setSelectedReports] = useState([])
  const [scheduleList, setScheduleList] = useState([])
  const [selectedSchedule, setSelectedSchedule] = useState('')
  const [subscriberEmail, setSubscriberEmail] = useState('')
  const [subscriptionName, setSubscriptionName] = useState('')
  const [customSubject, setCustomSubject] = useState(false)

  const emailScheduleRef = useRef('')
  const frequencyRef = useRef('')

  const emailSchedulePath = window.location.pathname === '/report-email-schedules'
  const emailScheduleReportPath = window.location.pathname === '/reports' || window.location.pathname === '/'

  const toggleModal = () => {
    setShowModal(!showModal)
    clearForm()
  }

  const fetchSchedulesList = (id) => {
    return axios.get('/report-email-schedule-props/schedules', { params: { report_id: id } })
      .then(res => setScheduleList(res.data.schedules))
      .catch((error) => console.log(error))
  }

  const fetchProps = (id) => {
    const params = (emailScheduleRef.current || emailSchedulePath) ? { report_email_schedule_id: id } : { report_id: id }
    return axios.get('/report-email-schedule-props/props', { params })
      .then(res => {
        const {
          csrf_token,
          email_schedule_reports,
          frequencies,
          reports,
          report_email_schedule,
          subscriber_id,
          subscriber_email,
          subscription_name,
        } = res.data.props

        setCsrfToken(csrf_token)
        setFrequencies(frequencies)
        setSubscriberId(subscriber_id)
        setReports(reports)
        setSelectedReports(email_schedule_reports)
        setSubscriberEmail(subscriber_email)
        setSubscriptionName(subscription_name)

        const setDate = (res) => {
          return res.begin_at ? new Date(res.begin_at) : new Date()
        }

        report_email_schedule && setFieldValues({
          beginAt: setDate(report_email_schedule),
          frequency: report_email_schedule.frequency || '',
          subject: report_email_schedule.subject || '',
        })
      })
      .catch((error) => console.log(error))
  }

  const handleModalOpen = (resourceId) => {
    resourceId && emailSchedulePath && setEmailScheduleId(resourceId)

    resourceId && emailScheduleReportPath && fetchSchedulesList(resourceId)
    fetchProps(resourceId)
    toggleModal()
  }

  useEffect(() => {
    window.subscribeToRes(handleModalOpen)

    return () => {
      clearForm()
    }
  }, [setShowModal])

  useEffect(() => {
    frequencyRef.current = fieldValues.frequency
    window.subscribeToSchedule(sendEmailNow)
    window.subscribeToDelete(deleteSchedule)
  })

  const clearForm = () => {
    emailScheduleRef.current = ''
    frequencyRef.current = ''
    emailScheduleReportPath && setEmailScheduleId('')
    emailScheduleReportPath && setSelectedSchedule('')
    setCustomSubject(false)
    setFieldValues({
      beginAt: new Date(),
      frequency: '',
      subject: '',
    })
  }

  const sendEmailNow = (scheduleId) => {
    MySwal.fire({
      title: 'Success!',
      text: 'Your report is being sent',
      icon: 'success',
      confirmButtonText: 'Ok'
    })
    axios.get('/report-email-schedules/send_now', { params: { id: scheduleId } })
      .then(res => console.log('res',res))
      .catch(error => console.log(error))
  }

  const deleteSchedule = (scheduleId, token) => {
    const params =  {
      authenticity_token: token,
    }

    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((res) => {
      if (res.isConfirmed) {
        axios.delete(`/report-email-schedules/${scheduleId}`, { params })
          .then(res => {
            location.reload()
          })
          .catch(error => console.log(error))
      }
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setCustomSubject(true)
    setFieldValues({ ...fieldValues, [name]: value })
  }

  const handleDateChange = (value) => {
    setFieldValues({ ...fieldValues, beginAt: value })
  }

  const handleFrequencyChange = (event) => {
    frequencyRef.current = event.value
    customSubject ? setFieldValues({...fieldValues, frequency: event.value}) : setDefaultEmailSubject(selectedReports)
  }

  const handleScheduleChange = (event) => {
    setSelectedSchedule(event.value)
    setEmailScheduleId(event.value)
    emailScheduleRef.current = event.value
    fetchProps(event.value)
    setFieldValues({
      ...fieldValues,
      begin_at: event.begin_at,
      frequency: event.frequency,
      subject: event.label,
    })
  }

  const handleCreateNewSchedule = () => {
    setEmailScheduleId('')
    setScheduleList([])
    setSelectedReports([])
    setFieldValues({
      begin_at: new Date(),
      frequency: '',
      subject: '',
    })
  }

  const handleReportsChange = (event) => {
    const value = event ? event.map((e) => ({ value: e.value, label: e.label, shared: e.shared })) : []
    setSelectedReports(value)
    !customSubject && setDefaultEmailSubject(value)
  }

  const setDefaultEmailSubject = (value) => {
    const reports = value && value.map(report => report.label).join(', ')
    const reportArr = reports.split(',')
    const reportList = reportArr.length > 1 ? `${reportArr[0]} + ${reportArr.slice(1).length} more` : reportArr[0]
    frequencyRef.current === undefined ? frequencyRef.current = '' : frequencyRef.current
    setFieldValues({...fieldValues, frequency: frequencyRef.current, subject: `Marketview Report - ${reportList} - ${frequencyRef.current} - ${subscriptionName}` })
  }

  const form = showModal && (
    <Form
      csrfToken={csrfToken}
      emailScheduleId={emailScheduleId}
      fieldValues={fieldValues}
      frequencies={frequencies}
      handleChange={handleChange}
      handleCreateNewSchedule={handleCreateNewSchedule}
      handleDateChange={handleDateChange}
      handleFrequencyChange={handleFrequencyChange}
      handleReportsChange={handleReportsChange}
      handleScheduleChange={handleScheduleChange}
      modalOpen={showModal}
      reports={reports}
      selectedReports={selectedReports}
      selectedSchedule={selectedSchedule}
      scheduleList={scheduleList}
      subscriberEmail={subscriberEmail}
      subscriberId={subscriberId}
      subscriptionName={subscriptionName}
      toggle={toggleModal}
    />
  )

  return (
    <>
      {form}
    </>
  )
}

export default EmailScheduleTool
