import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const ImageFormatter = ({ row, value }) => (
  <img src={value} alt={row.providerName || 'Provider Logo'} />
)

const ImageTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={ImageFormatter}
    {...props}
  />
)

export default ImageTypeProvider
