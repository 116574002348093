// a Param can be an array, an integer, a float, an Enum or a string
// the graphql query, however, is just a string. This function returns the string part of your param
// that is:
// an array of numbers becomes: "[1,2,3]"
// an array of string becomes: "[\"hello\", \"world\"]"
// a number stays as is
// a string becomes: "\"hello\""
// an Enum becomes: "OWNER" (note it has NO quotes around it)
function encodedParam(value) {
  if (typeof value === 'string' && value === value.toUpperCase()) {
    // Enum. Don't quote
    return value
  }
  if (typeof value === 'string') {
    return `"${value}"`
  }
  if (typeof value === 'number') {
    return value
  }
  if (value instanceof Array) {
    return `[${value.map(entry => encodedParam(entry)).join(',')}]`
  }
  // unknown
  return value
}

export default function queryBuilder(props) {
  const { endpoint, args, fields } = props
  const formattedArgs = Object.entries(args).filter(([_, v]) => v != '').map(entry => (
    `${entry[0]}: ${encodedParam(entry[1])}`
  )).join(',');

  return `{ ${endpoint}(${formattedArgs}) { ${fields} } }`
}
