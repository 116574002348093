import PropTypes from 'prop-types'
import React from 'react'
import startCase from 'lodash.startcase'
import MozoStyledSelect from './mozo-styled-select'
import withFormRow from '../../components/hoc/with-form-row'

const lockedFieldInput = (props) => {
  const {
    endpoints, endpoint, fields, locked, onChange,
  } = props

  const fullOptions = endpoints[endpoint] && endpoints[endpoint].fields.map(name => (
    { value: name, label: startCase(name) }
  ))

  const selectOptions = fullOptions ? fullOptions.filter(option => fields.includes(option.value)) : []

  const selected = selectOptions ? selectOptions.filter(option => locked.includes(option.value)) : []

  return (
    <MozoStyledSelect
      {...props}
      value={selected}
      options={selectOptions}
      placeholder="Choose the columns which lock in place and do not scroll off the page"
      isSearchable
      isClearable
      closeMenuOnSelect={false}
      isMulti
      onChange={e => onChange('locked', e.map(data => data.value))}
    />
  )
}

lockedFieldInput.propTypes = {
  endpoint: PropTypes.string.isRequired,
  endpoints: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  locked: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withFormRow(lockedFieldInput, 'Locked columns')
