import homeLoanFilterSummary from './home-loan-filter-summary'
import bankAccountFilterSummary from './bank-account-filter-summary'
import creditCardFilterSummary from './credit-card-filter-summary'
import personalLoanFilterSummary from './personal-loan-filter-summary'
import savingsAccountFilterSummary from './savings-account-filter-summary'
import termDepositFilterSummary from './term-deposit-filter-summary'

const filterSummary = (args, endpoint) => {
  if (endpoint === 'homeLoans') {
    return homeLoanFilterSummary(args)
  }
  if (endpoint === 'bankAccounts') {
    return bankAccountFilterSummary(args)
  }
  if (endpoint === 'creditCards') {
    return creditCardFilterSummary(args)
  }
  if (endpoint === 'personalLoans') {
    return personalLoanFilterSummary(args)
  }
  if (endpoint === 'savingsAccounts') {
    return savingsAccountFilterSummary(args)
  }
  if (endpoint === 'termDeposits') {
    return termDepositFilterSummary(args)
  }
  return ''
}

export default filterSummary
