import React from 'react'
import Select from 'react-select';

const SelectInput = (props) => {
  return (
    <Select {...props} />
  )
}

export default SelectInput
