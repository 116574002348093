import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import startCase from 'lodash.startcase'
import MozoStyledSelect from '../components/mozo-styled-select'

function predefinedOptions(name) {
  const predefinedArgumentOptions = [
    'distributor',
    'distributorId',
    'gtsLinksOnly',
    'providerGroupIds',
    'providerIds',
    'publiclyListed',
    'state',
  ]
  return predefinedArgumentOptions.includes(name)
}

function optionLabel(label, value) {
  return predefinedOptions(label) ? value : startCase(value.split(' ').splice(1).join(' '))
}

function stringToArray(value) {
  return value && value.toString().replace('[', '').replace(']', '').replace(/ /g, '').split(',')
}

const providerName = (name) => {
  return name === 'providerIds' ? 'Provider' : null
}

const providerGroupName = (name) => {
  return name === 'providerGroupIds' ? 'Provider Group' : null
}

class ArgumentInput extends Component {
  constructor(props) {
    super(props)
    const { args, name } = props
    this.toggle = this.toggle.bind(this)
    this.state = {
      inputValue: args[name] || '',
      label: providerName(name) || providerGroupName(name) || startCase(name),
      tooltipOpen: false,
    }
  }

  componentDidMount() {
    const { defaultValue } = this.props
    const { inputValue } = this.state
    if (inputValue === '') {
      this.setState({ inputValue: defaultValue })
    }
  }

  onChange(data) {
    const { onValueChange, name } = this.props
    const value = data && data.value
    this.setState({ inputValue: value })
    onValueChange(name, data)
  }

  textUpdate(event) {
    event.persist()
    const inputValue = event.target.value === '' ? '' : (parseInt(event.target.value, 10) || 0).toString()
    this.onChange({ value: inputValue })
  }

  toggle() {
    this.setState((prevState) => ({ tooltipOpen: !prevState.tooltipOpen }))
  }

  render() {
    const {
      args, description, includeTooltip, name, options, type,
    } = this.props

    const {
      inputValue,
      label,
      tooltipOpen,
    } = this.state

    const selectOptions = (options || []).map((obj) => (
      {
        key: obj.id,
        value: obj.id,
        label: includeTooltip ? startCase(obj.name) : optionLabel(name, obj.name),
        name,
      }
    ))

    const selection = stringToArray(args && args[name] && args[name])
    const defaultOptions = selectOptions.filter((o) => selection && selection.includes(o.value.toString()))

    const select = type === 'select' && (
      <MozoStyledSelect
        options={selectOptions}
        defaultValue={defaultOptions}
        placeholder="Optional. Select one..."
        isSearchable
        isClearable
        onChange={(e) => this.onChange(e)}
      />
    )

    const multi = type === 'multi' && (
      <MozoStyledSelect
        options={selectOptions}
        defaultValue={defaultOptions}
        placeholder="Optional. Select 1 or more..."
        isSearchable
        isClearable
        isMulti
        closeMenuOnSelect={false}
        onChange={(e) => this.onChange(e)}
      />
    )

    const text = type === 'text' && (
      <Input
        type="text"
        name={name}
        placeholder="Optional. Enter your value..."
        onChange={(e) => this.textUpdate(e)}
        value={inputValue || ''}
      />
    )

    // TODO: This should be it's own component
    const tooltip = includeTooltip && description && (
      <>
        <button type="button" className="btn p-0" onClick={this.toggle}>
          <span className="far fa-info-circle" />
        </button>
        <Modal isOpen={tooltipOpen}>
          <ModalBody>
            {description}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    )

    return (
      <FormGroup className="mv2-z-index">
        <Label htmlFor={name}>
          {label}
        </Label>
        {' '}
        {tooltip}
        {select}
        {multi}
        {text}
      </FormGroup>
    )
  }
}

ArgumentInput.propTypes = {
  args: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  description: PropTypes.string,
  includeTooltip: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
  })),
  type: PropTypes.string.isRequired,
}

ArgumentInput.defaultProps = {
  description: null,
  includeTooltip: true,
  options: null,
}

export default ArgumentInput
