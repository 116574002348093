import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import ToolbarLinkButton from '../data-grid/components/toolbar-link-button'

const pluginDependencies = [
  { name: 'Toolbar' },
];

export class ProductTypePanel extends React.PureComponent {
  render() {
    const { csvUrl } = this.props;

    return (
      <Plugin
        name="DownloadCsvPanel"
        dependencies={pluginDependencies}
      >
        <Template name="toolbarContent">
          <TemplatePlaceholder />
          <TemplateConnector>
            {({}) => (
              <ToolbarLinkButton
                label="Download CSV"
                href={csvUrl}
              />
            )}
          </TemplateConnector>
        </Template>
      </Plugin>
    );
  }
}

ProductTypePanel.propTypes = {
  endpoint: PropTypes.string.isRequired,
};
