import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const formatDate = (value) => {
  // Safari can't handle 2020-07-22. Needs 2020/07/22
  const d = new Date(value.replace(/-/g, '/'))
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()]
  return `${d.getDate()} ${month} ${d.getFullYear()}`
}

const DateFormatter = ({ value }) => (value && formatDate(value))

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    {...props}
  />
)

export default DateTypeProvider
