import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'

const alertModal = (props) => {
  const {
    className, buttonText, title, body, modalOpen, toggle,
  } = props

  return (
    <Modal isOpen={modalOpen} toggle={toggle} className={className}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>{buttonText}</Button>
      </ModalFooter>
    </Modal>
  )
}

alertModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  toggle: PropTypes.func.isRequired,
}

alertModal.defaultProps = {
  className: '',
  buttonText: 'I understand',
  title: 'Warning',
}

export default alertModal
