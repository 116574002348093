import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import ReportButton from '../../utils/ui/report-button'
import { withToggle } from '../../components/hoc/index'
import FixedColumnModal from './fixed-column-modal'

const fixedColumnButton = (props) => {
  const { modalOpen, toggle } = props

  return (
    <Fragment>
      <ReportButton
        className="btn btn-light btn-icon"
        icon="fas fa-lock"
        id="fixed-column"
        onClick={toggle}
        type="button"
      />
      <UncontrolledTooltip placement="top" target="fixed-column">
        Locked data columns
      </UncontrolledTooltip>
      <FixedColumnModal {...props} modalOpen={modalOpen} toggle={toggle} />
    </Fragment>
  )
}

fixedColumnButton.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default withToggle(fixedColumnButton)
