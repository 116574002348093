const customerType = args => (args.customerType)

const cardType = args => (args.cardType)

const annualSpend = args => (args.annualSpend && `$${parseInt(args.annualSpend / 1000)}k`)

const balanceTransfer = args => (
  {
    WITHBALANCETRANSFER: 'BT',
    WITHOUTBALANCETRANSFER: 'NO BT',
    ZEROBALANCETRANSFER: '0% BT',
    NONZEROBALANCETRANSFER: '>0% BT',
  }[args.balanceTransfer]
)

const introRate = args => (
  {
    WITHINTRORATE: 'INTRO',
    WITHOUTINTRORATE: 'NO INTRO',
    ZEROINTRORATE: '0% INTRO',
    NONZEROINTRORATE: '>0% INTRO',
  }[args.introRate]
)

const rewardsProgram = args => (
  {
    WITHREWARDS: 'REWARDS',
    WITHOUTREWARDS: 'NO REWARDS',
  }[args.rewardsProgram]
)

const maximumAnnualFee = args => (
  args.maximumAnnualFee && `FEE <$${args.maximumAnnualFee}`
)

const maximumRate = args => (
  args.maximumRate && `RATE <=${args.maximumRate}%`
)

const creditCardFilterSummary = args => (
  [
    customerType(args),
    cardType(args),
    annualSpend(args),
    balanceTransfer(args),
    introRate(args),
    rewardsProgram(args),
    maximumAnnualFee(args),
    maximumRate(args),
  ].filter(Boolean).join(' | ')
)

export default creditCardFilterSummary
