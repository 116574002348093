import { Getter, Plugin } from "@devexpress/dx-react-core";
import * as React from "react";

const recalculateViewport = ({ viewport, tableColumns }) => {
  return {
    ...viewport,
    columns: [[0, tableColumns.length - 1]]
  };
};

export const ColumnVirtualizationDisabler = () => {
  return (
    <Plugin>
      <Getter name="viewport" computed={recalculateViewport} />
    </Plugin>
  );
};