import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const mozoStyledSelect = (props) => {
  const { fieldValid, touched } = props
  const invalid = touched && !fieldValid
  const validColor = invalid ? '#DD1C1A' : '#11C25D'
  const borderColor = touched ? validColor : '#CED4DA'
  const onFocusBorderColor = touched ? validColor : '#51D9F9'
  const validBoxShadow = invalid ? '0 0 0 0.2rem rgba(221, 28, 26, 0.25)' : '0 0 0 0.2rem rgba(17, 194, 93, 0.25)'
  const boxShadow = touched ? validBoxShadow : '0 0 0 0.2rem rgba(7, 160, 195, 0.25)'
  const warning = invalid ? {
    backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23DD1C1A' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23DD1C1A' stroke='none'/%3e%3c/svg%3e\")",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right calc(0.375em + 2.3rem) center',
    backgroundSize: 'calc(0.75em + 0.6rem) calc(0.75em + 0.6rem)',
  } : {
    backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2311C25D' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e\")",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right calc(0.375em + 4rem) center',
    backgroundSize: 'calc(0.75em + 0.6rem) calc(0.75em + 0.6rem)',
  }
  const showWarning = touched && warning

  const styles = {
    control: (provided) => ({
      ...provided,
      ...showWarning,
      backgroundColor: '#FBFBFB',
      borderRadius: '8px',
      borderColor,
      boxShadow: 'none',
      ':hover': {
        borderColor: onFocusBorderColor,
        boxShadow,
      },
      fontSize: '0.875rem',
      minHeight: '42px',
    }),
  }

  return (
    <Select
      {...props}
      styles={styles}
    />
  )
}

mozoStyledSelect.propTypes = {
  fieldValid: PropTypes.bool,
  touched: PropTypes.bool,
}

export default mozoStyledSelect
