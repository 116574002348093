import React, { Component } from 'react'

function withFormRow(WrappedComponent, rowLabel) {
  return class extends Component {
    render() {
      return (
        <div className="form-group">
          <label htmlFor="name">
            {rowLabel}
          </label>
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }
}

export default withFormRow
