const bankAccountType = (args) => ( args.type )

const bankAccountFee = (args) => ( args.noFees !== undefined && ( args.noFees ? 'NO FEE' : 'FEE') )

const accountBalance = args => (args.accountBalance && `$${parseInt(args.accountBalance / 1000)}k`)


const bankAccountFilterSummary = (args) => (
  [
    bankAccountType(args),
    bankAccountFee(args),
    accountBalance(args),
  ].filter(Boolean).join(' | ')
)

export default bankAccountFilterSummary