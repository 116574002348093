import React, { useState } from 'react'
import axios from 'axios'
import ScheduleModal from './schedule-modal'

const Form = (props) => {
  const [errors, setErrors] = useState('')
  const {
    csrfToken,
    emailScheduleId,
    fieldValues,
    selectedReports,
    subscriberId,
    toggle,
  } = props

  const handleSubmit = (event) => {
    event.preventDefault()

    const {
      beginAt,
      frequency,
      subject,
    } = fieldValues
    const reportIds = selectedReports.map(r => r.value)

    const params =  {
      authenticity_token: csrfToken,
      begin_at: beginAt,
      frequency,
      report_ids: reportIds,
      subject,
      subscriber_id: subscriberId,
    }

    const endpoint = emailScheduleId ? `/report-email-schedules/${emailScheduleId}` : '/report-email-schedules'
    const httpMethod = emailScheduleId ? 'put' : 'post'

    axios({
      method: httpMethod,
      url: endpoint,
      data: params
      })
      .then((res) => {
        if (res.data.errors.length > 0) {
          setErrors(res.data.errors.join(', '))
        } else {
          toggle()
          location.reload()
        }
      })
      .catch((err) => {
        console.log('Oops! We got an error', err);
      })
  }

  return (
    <ScheduleModal errors={errors} {...props} handleSubmit={handleSubmit} />
  )
}

export default Form
