import React, { Component, Fragment } from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

class ProviderGroupType extends Component {

  render() {
    const { providerGroups } = this.props

    const classNames = (value) => {
      const match = providerGroups.find((k) => k.name === value)
      const position = match === undefined ? 0 : match.position
      return 'provider-group provider-group--' + String(position)
    }

    const ProviderGroupTypeFormatter = ({value}) => {
      return (
        <Fragment>
          <div className={classNames(value)}></div>
          <b>{value}</b>
        </Fragment>
      )
    }

    const columnNames = ['providerGroupName']

    return <DataTypeProvider
      formatterComponent={ProviderGroupTypeFormatter}
      for = {columnNames}
    />

  }
}

export default ProviderGroupType