import React, { useState } from 'react';
import SelectInput from './select-input';

const ReportSelect = (props) => {
  const { handleChange, reports, selectedReports } = props

  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      color: state.data.shared ? '#26d' : '#555',
      backgroundColor: state.data.shared ? '#eef' : '#eee',
    }),
  }

  return (
    <SelectInput
      value={selectedReports}
      onChange={handleChange}
      options={reports}
      isClearable
      isMulti
      isSearchable
      placeholder="Optional. Select 1 or more..."
      styles={customStyles}
    />
  )
}

export default ReportSelect
