import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const FlatPercentFormatter = ({ value }) => (
  value === null || value === undefined ? <span> </span> : <span>{`${value.toFixed(0)}%`}</span>
)

const FlatPercentTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={FlatPercentFormatter}
    {...props}
  />
)

export default FlatPercentTypeProvider
