import 'core-js/stable'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Select from 'react-select'
import {
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  TableColumnVisibility,
  TableHeaderRow,
  Table,
} from '@devexpress/dx-react-grid-bootstrap4'
import { BooleanTypeProvider, DateTypeProvider, HumaniseTypeProvider } from '../../utils/grid/formatters/index'
import ActionsTypeProvider from '../components/actions-type-provider'
import NewReportButton from '../components/new-report-button'

class ReportGrid extends Component {
  state = {
    selectedOption: '',
  }

  deleteRow = (id) => {
    let { rows } = this.state
    rows = rows.filter(row => row.id !== id)
    this.setState({ rows })
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption })
  }

  getFilteredRow = (option) => {
    const { rows } = this.props
    const filteredRow = rows && rows.filter(row => (
     row.shared === option.value
    ))
    return filteredRow
  }

  render() {
    const { rows } = this.props
    const { selectedOption } = this.state
    const filteredRows = selectedOption && selectedOption.value === 'all' ? rows : this.getFilteredRow(selectedOption)
    const columns = [
      { name: 'endpoint', title: 'Product Type' },
      { name: 'name', title: 'Name' },
      { name: 'shared', title: 'Shared?' },
      { name: 'created_at', title: 'Created' },
      { name: 'updated_at', title: 'Last Update' },
      { name: 'id', title: '' },
      { name: 'can_edit', title: 'canEdit?' },
      { name: 'actions', title: 'Actions' },
    ]

    const columnExt = [
      { columnName: 'actions', width: 200 },
      { columnName: 'endpoint', width: 200 },
      { columnName: 'shared', align: 'center', width: 140 },
      { columnName: 'name', wordWrapEnabled: true },
    ]

    const options = [
      { value: true, label: 'Shared' },
      { value: false, label: 'Non shared' },
      { value: 'all', label: 'All' },
    ]
    return (
      <Fragment>
         <div className="card-body">
            <NewReportButton />
            <Select options={options} onChange={this.handleChange} value={selectedOption} />
         </div>
        <Grid rows={filteredRows || rows} columns={columns}>
          <ActionsTypeProvider for={['actions']} />
          <BooleanTypeProvider for={['shared']} />
          <DateTypeProvider for={['created_at', 'updated_at']} />
          <HumaniseTypeProvider for={['endpoint']} />
          <SortingState
            defaultSorting={[{ columnName: 'endpoint', direction: 'asc' }]}
          />
          <IntegratedSorting />
          <Table
            columnExtensions={columnExt}
          />
          <TableHeaderRow showSortingControls />
          <TableColumnVisibility
            defaultHiddenColumnNames={['id', 'can_edit']}
          />
        </Grid>
      </Fragment>
    )
  }
}

ReportGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}


export default ReportGrid
