import React from 'react'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ReportSelect from './components/report-select'
import FrequencySelect from './components/frequency-select'
import ErrorAlert from './components/error-alert'

const EmailScheduleForm = (props) => {
  const {
    errors,
    fieldValues,
    frequencies,
    handleChange,
    handleDateChange,
    handleFrequencyChange,
    handleReportsChange,
    handleSubmit,
    reports,
    selectedReports,
    subscriberEmail,
  } = props

  const sharedReports = selectedReports.filter(report => report.shared === true)
  const sharedReportList = sharedReports.map(report => <li style={styles.li} key={report.value}><small>{report.label}</small></li>)
  const renderedList = sharedReports.length > 0 ? (
    <>
      <p><small className="mt-4">Please note that 'Shared' reports can be modified, or deleted by their creator. To ensure you receive the correct report we suggest making a copy of it first.<br/>This Email Schedule includes the following shared reports:</small></p>
      <ul style={styles.ul}>
        {sharedReportList}
      </ul>
    </>
  ) : ''
  const errorMessage = errors && <ErrorAlert error={errors} />

  return (
    <Form onSubmit={(e) => handleSubmit(e)} className="b-create-report__form">
      <FormGroup>
        {errorMessage}
        <Label for="reports">Reports *</Label>
        <ReportSelect
          id="reports"
          handleChange={handleReportsChange}
          reports={reports}
          selectedReports={selectedReports}
        />
      </FormGroup>
      <FormGroup>
        <Label for="frequency">Frequency *</Label>
        <FrequencySelect
          id="frequency"
          frequencies={frequencies}
          frequencyValue={fieldValues.frequency}
          handleChange={handleFrequencyChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="subject">Subject</Label>
        <Input
          type="text"
          name="subject"
          id="subject"
          value={fieldValues.subject}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="beginAt">Email Start Date</Label>
        <DatePicker
          selected={fieldValues.beginAt}
          name="beginAt"
          id="beginAt"
          dateFormat="dd-MM-yyyy HH:mm aa"
          timeFormat="HH:mm"
          minDate={new Date()}
          onChange={(value) => handleDateChange(value)}
          placeholderText="Click to select a date"
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
        />
      </FormGroup>
      {renderedList}
      <div className="text-muted text-center mt-3"><b>Email will be sent to:</b> {subscriberEmail}</div>
      <div className="text-muted text-center mt-3"><b>All times are in AEST (Syd, Can, Melb) time.</b></div>
    </Form>
  )
}

const styles = {
  li: {
    backgroundColor: '#eef',
    width: 'fit-content',
  },
  ul: {
    listStyleType: 'disc',
    paddingLeft: '20px',
  }
}

export default EmailScheduleForm
