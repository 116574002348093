import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const BoldFormatter = ({ value }) => (
  <b>{value}</b>
)

const BoldTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={BoldFormatter}
    {...props}
  />
)

export default BoldTypeProvider
