import React, { Component } from 'react'
import { FormGroup } from 'reactstrap'

function withFieldValidation(WrappedComponent, errorMessage) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        touched: false,
        fieldValid: true,
      }
    }

    validateHandler = (value) => {
      const valid = Array.isArray(value) ? value.length > 0 : (value && value !== '')
      this.setState({ touched: true, fieldValid: valid })
    }

    render() {
      const { touched, fieldValid } = this.state
      const showInvalid = touched && !fieldValid
      const errorStyle = (showInvalid && { display: 'block' }) || {}
      const message = errorMessage || 'This field is required'

      return (
        <FormGroup>
          <WrappedComponent
            {...this.props}
            fieldValid={fieldValid}
            touched={touched}
            validateHandler={this.validateHandler}
          />
          <div style={errorStyle} className="invalid-feedback">{message}</div>
        </FormGroup>
      )
    }
  }
}

export default withFieldValidation
