import 'babel-polyfill'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FileUpload from '../user-guides/file-upload'

function UserGuideUploadApp(props) {
  const { previousUserGuide } = props
  const previousGuideAvailable = previousUserGuide === null

  const [oldUserGuide, setOldUserGuide] = useState(previousGuideAvailable)
  const [newUserGuide, setNewUserGuide] = useState({})

  const updateGuideList = (data) => {
    setNewUserGuide(data)
    setOldUserGuide(false)
  }

  const uploadAtDate = Date.parse(newUserGuide.uploadedAt || (previousUserGuide && previousUserGuide.created_at))
  const formattedDate = uploadAtDate && new Intl.DateTimeFormat('en-GB').format(uploadAtDate)

  return (
    <div>
      <FileUpload updateGuideList={updateGuideList} />

      <div className="row dropzone-outer-div">
        <div className="col-sm-9">
          <h3>Last uploaded file</h3>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead className="thead-dark">
          <tr>
            <th scope="col">Path</th>
            <th scope="col">Uploaded On</th>
          </tr>
          </thead>
          <tbody>
            { oldUserGuide ? (
              <tr>
                <td className="align-middle">No files uploaded</td>
                <td className="align-middle" />
              </tr>
              ) : (
              <tr>
                <td className="align-middle">{newUserGuide.path || previousUserGuide.path}</td>
                <td className="align-middle">{formattedDate}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

UserGuideUploadApp.propTypes = {
  previousUserGuide: PropTypes.shape({
    path: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
  }).isRequired,
}

export default UserGuideUploadApp
