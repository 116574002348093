// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var React = require("react")
var ReactDOM = require("react-dom")
var ReactRailsUJS = require("react_ujs")

ReactRailsUJS.mountComponents = function(searchSelector) {
  var ujs = ReactRailsUJS
  var nodes = ujs.findDOMNodes(searchSelector);

  for (var i = 0; i < nodes.length; ++i) {
    var node = nodes[i];
    var className = node.getAttribute(ujs.CLASS_NAME_ATTR);
    var constructor = ujs.getConstructor(className);
    var propsJson = node.getAttribute(ujs.PROPS_ATTR);
    var props = propsJson && JSON.parse(propsJson);

    // Mozo altered begin
    if (Object.keys(props).length == 0) { // find the script with the same name as the component
      var scriptProps = document.querySelector("script[type='text/json'][data-react-props-for='" + className + "']")
      if (scriptProps !== null) {
        props = JSON.parse(scriptProps.text)
      }
    }
    // Mozo altered end

    var hydrate = node.getAttribute(ujs.RENDER_ATTR);

    if (!constructor) {
      var message = "Cannot find component: '" + className + "'"
      if (console && console.log) {
        console.log("%c[react-rails] %c" + message + " for element", "font-weight: bold", "", node)
      }
      throw new Error(message + ". Make sure your component is available to render.")
    } else {
      if (hydrate && typeof ReactDOM.hydrate === "function") {
        ReactDOM.hydrate(React.createElement(constructor, props), node);
      } else {
        ReactDOM.render(React.createElement(constructor, props), node);
      }
    }
  }
}

ReactRailsUJS.useContext(componentRequireContext)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)
