import React from 'react'
import { Button } from 'reactstrap'

const newReportButton = () => (
  <Button
    href={`${window.location}/new`}
    color="success"
    style={{ marginBottom: '1.25rem' }}
  >
    Create New Report
  </Button>
)

export default newReportButton
