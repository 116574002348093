import React, { useState } from 'react'
import { startCase } from 'lodash'
import SelectInput from './select-input';

const FrequencySelect = (props) => {
  const { frequencies, frequencyValue, handleChange } = props

  const value = frequencies.find(item => item.value === frequencyValue)

  return (
    <SelectInput
      value={value}
      onChange={handleChange}
      options={frequencies}
      isSearchable
			name="frequency"
      placeholder="Select 1 option"
    />
  )
}

export default FrequencySelect
