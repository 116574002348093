import 'core-js/stable'
import React, { Component } from 'react'
import { Card } from 'reactstrap'
import ReportGrid from '../reports-index/containers/report-grid'

class ReportsIndexApp extends Component {
  render() {
    return (
      <Card>
        <ReportGrid {...this.props} />
      </Card>
    )
  }
}

export default ReportsIndexApp
