import React from 'react'

function withToggle(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modalOpen: false,
      };
    }

    toggle = () => {
      const { modalOpen } = this.state
      this.setState({ modalOpen: !modalOpen })
    }

    render() {
      const { modalOpen } = this.state
      return <WrappedComponent {...this.props} modalOpen={modalOpen} toggle={this.toggle} />
    }
  }
}

export default withToggle
