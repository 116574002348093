import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const YearFormatter = ({ value }) => (value && `${value} ${value === 1 ? "year" : "years"}`)

const YearTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={YearFormatter}
    {...props}
  />
)

export default YearTypeProvider
