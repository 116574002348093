import PropTypes from 'prop-types'
import React from 'react'
import { Alert, Row, Col } from 'reactstrap'
import ArgumentInput from '../containers/argument-input'
import withFormRow from '../../components/hoc/with-form-row'
import ShowInReport from './show-in-report'

const argumentOptions = (props) => {
  const {
    args, endpoints, endpoint, inputs, onArgumentInput,
  } = props
  const data = endpoints[endpoint]

  const allInputs = endpoint && (
    data.arguments.map(arg => (
      <ArgumentInput {...arg} args={args} key={arg.name} onValueChange={onArgumentInput} />
    ))
  )

  const argInputs = allInputs && allInputs.map((arg, index) => {
    const inputName = data.arguments[index].name
    const show = inputs.includes(inputName)

    return (
      <Col key={inputName} md={6}>
        <Row>
          <div className="col-9">
            {arg}
          </div>
          <div className="col-3">
            <ShowInReport {...props} name={inputName} show={show} />
          </div>
        </Row>
      </Col>
    )
  })

  const view = allInputs ? argInputs : (
    <Col md={6}>
      <Alert color="light">When you choose an Api endpoint, available filter options will appear here.</Alert>
    </Col>
  )

  return (
    <Row>
      {view}
    </Row>
  )
}

argumentOptions.propTypes = {
  args: PropTypes.shape({}).isRequired,
  endpoint: PropTypes.string.isRequired,
  endpoints: PropTypes.shape({}).isRequired,
  inputs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onArgumentInput: PropTypes.func.isRequired,
}

export default withFormRow(argumentOptions)
