import React from 'react'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ReportSelect from './components/report-select'
import FrequencySelect from './components/frequency-select'
import EmailScheduleForm from './email-schedule-form'
import ReportEmailScheduleForm from './report-email-schedule-form'

const ScheduleModal = (props) => {
  const {
    emailScheduleId,
    errors,
    fieldValues,
    frequencies,
    handleChange,
    handleCreateNewSchedule,
    handleDateChange,
    handleFrequencyChange,
    handleReportsChange,
    handleScheduleChange,
    handleSubmit,
    modalOpen,
    reports,
    scheduleList,
    selectedReports,
    selectedSchedule,
    subscriberEmail,
    toggle,
  } = props

  const showEmailScheduleForm = scheduleList.length === 0 || emailScheduleId

  const scheduleForm = showEmailScheduleForm ? (
    <EmailScheduleForm
      errors={errors}
      fieldValues={fieldValues}
      frequencies={frequencies}
      handleChange={handleChange}
      handleDateChange={handleDateChange}
      handleFrequencyChange={handleFrequencyChange}
      handleReportsChange={handleReportsChange}
      handleSubmit={handleSubmit}
      reports={reports}
      selectedReports={selectedReports}
      subscriberEmail={subscriberEmail}
    />
    ) : (
    <ReportEmailScheduleForm
      handleCreateNewSchedule={handleCreateNewSchedule}
      handleScheduleChange={handleScheduleChange}
      selectedSchedule={selectedSchedule}
      scheduleList={scheduleList}
    />
  )
  const footer = showEmailScheduleForm && (
    <ModalFooter>
      <Button type="submit" color="primary" onClick={handleSubmit} >Save</Button>
      <Button color="secondary" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  )

  return (
    <Modal isOpen={modalOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Email Schedule</ModalHeader>
      <ModalBody>
        {scheduleForm}
      </ModalBody>
      {footer}
    </Modal>
  )
}


export default ScheduleModal
