import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  SearchState,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  TableColumnReordering,
  TableColumnResizing,
  TableFixedColumns,
  TableGroupRow,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4'
import {
  BoldTypeProvider,
  BooleanTypeProvider,
  CurrencyTypeProvider,
  DateTypeProvider,
  FlatPercentTypeProvider,
  ImageTypeProvider,
  NumberTypeProvider,
  PercentTypeProvider,
  SignificantPercentTypeProvider,
  WrappedTypeProvider,
  YearTypeProvider,
  ProviderGroupType,
} from '../../utils/grid/formatters/index'
import EmptyDataRow from './empty-data-row'
import { ColumnVirtualizationDisabler } from "./custom-plugins";

const dataGrid = (props) => {
  const {
    columns,
    fields,
    fixedColumns,
    rows,
    searchValue,
    showToolbar,
    tableHeight,
    providerGroups,
  } = props
  const [sorting, setSorting] = useState([{ columnName: 'providerGroupName', direction: 'asc' }])

  const firstRowKeys = rows.length > 0 ? Object.keys(rows[0]).filter((name) => name !== '__typename') : rows
  const columnData = firstRowKeys.map((k) => ({ name: k, title: columns[k].title }))
  const columnExt = firstRowKeys.map((k) => ({ columnName: k, align: columns[k].align || 'left', wordWrapEnabled: true }))
  const columnKeys = Object.keys(columns)
  const boldColumns = columnKeys.filter((k) => columns[k].format === 'Bold')
  const booleanColumns = columnKeys.filter((k) => columns[k].format === 'Boolean')
  const currencyColumns = columnKeys.filter((k) => columns[k].format === 'Money')
  const dateColumns = columnKeys.filter((k) => columns[k].format === 'Date')
  const flatPercentColumns = columnKeys.filter((k) => columns[k].format === 'FlatPercent')
  const imageColumns = columnKeys.filter((k) => columns[k].format === 'Image')
  const numberColumns = columnKeys.filter((k) => columns[k].format === 'Number')
  const percentColumns = columnKeys.filter((k) => columns[k].format === 'Percent')
  const significantPercentColumns = columnKeys.filter((k) => columns[k].format === 'SignificantPercent')
  const wrappedColumns = columnKeys.filter((k) => columns[k].format === 'Wrapped')
  const yearColumns = columnKeys.filter((k) => columns[k].format === 'InYears')
  const ProviderGroupColumns = columnKeys.filter((k) => columns[k].format === 'ProviderGroup')
  const defaultColumnOrder = fields.split(' ')
  const defaultColumnWidths = columnKeys.map((k) => ({ columnName: k, width: columns[k].size || 200 }))
  const nonSortableColumns = imageColumns.map((k) => ({ columnName: k, sortingEnabled: false }))
  const toolbar = showToolbar && <Toolbar />
  const groupingPanel = showToolbar && <GroupingPanel />

  const nullIsImportant = (value) => (value === null ? 999999999999999 : value)
  const nullIsInsignificant = (value) => (value === null ? -1 : value)
  const compareLightNullColumnValue = (a, b) => {
    const compareA = nullIsInsignificant(a)
    const compareB = nullIsInsignificant(b)
    return compareA - compareB
  }
  const compareHeavyNullColumnValue = (a, b) => {
    const compareA = nullIsImportant(a)
    const compareB = nullIsImportant(b)
    return compareA - compareB
  }
  const providerGroupWeighting = (a) => {
    const match = providerGroups.find((k) => k.name === a)
    return match === undefined ? -1 : match.position
  }
  const compareProviderGroupWeighting = (a, b) => {
    const compareA = providerGroupWeighting(a)
    const compareB = providerGroupWeighting(b)
    return compareA - compareB
  }
  const compareProviderNameOrder = (a, b) => (a.toLowerCase().localeCompare(b.toLowerCase()))

  const lightNullColumns = columnKeys.filter((k) => columns[k].sort === 'LightNull').map((k) => ({ columnName: k, compare: compareLightNullColumnValue }))
  const heavyNullColumns = columnKeys.filter((k) => columns[k].sort === 'HeavyNull').map((k) => ({ columnName: k, compare: compareHeavyNullColumnValue }))
  const providerGroupSortColumns = columnKeys.filter((k) => columns[k].sort === 'ProviderGroup').map((k) => ({ columnName: k, compare: compareProviderGroupWeighting }))
  const providerNameSortColumn = columnKeys.filter((k) => columns[k].sort === 'ProviderName').map((k) => ({ columnName: k, compare: compareProviderNameOrder }))
  const integratedSortingColumnExtensions = [...lightNullColumns, ...heavyNullColumns, ...providerGroupSortColumns, ...providerNameSortColumn]

  return (
    <Grid rows={rows} columns={columnData}>
      <BoldTypeProvider for={boldColumns} />
      <BooleanTypeProvider for={booleanColumns} />
      <CurrencyTypeProvider for={currencyColumns} />
      <DateTypeProvider for={dateColumns} />
      <FlatPercentTypeProvider for={flatPercentColumns} />
      <ImageTypeProvider for={imageColumns} />
      <NumberTypeProvider for={numberColumns} />
      <PercentTypeProvider for={percentColumns} />
      <SignificantPercentTypeProvider for={significantPercentColumns} />
      <WrappedTypeProvider for={wrappedColumns} />
      <YearTypeProvider for={yearColumns} />
      <ProviderGroupType for={ProviderGroupColumns} providerGroups={providerGroups} />
      <SearchState value={searchValue} />
      <SortingState
        sorting={sorting}
        onSortingChange={setSorting}
        columnExtensions={nonSortableColumns}
      />
      <IntegratedSorting
        columnExtensions={integratedSortingColumnExtensions}
      />
      <IntegratedFiltering />
      <DragDropProvider />
      <GroupingState />
      <IntegratedGrouping />
      <VirtualTable
        height={tableHeight}
        columnExtensions={columnExt}
        noDataRowComponent={() => <EmptyDataRow {...props} />}
      />
      <ColumnVirtualizationDisabler />
      <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
      <TableColumnReordering defaultOrder={defaultColumnOrder} />
      <TableHeaderRow showSortingControls />
      <TableGroupRow />
      <TableFixedColumns
        leftColumns={fixedColumns}
      />
      {toolbar}
      {groupingPanel}
    </Grid>
  )
}

dataGrid.propTypes = {
  args: PropTypes.shape({}).isRequired,
  csvUrl: PropTypes.string.isRequired,
  csvUrlWithNumeric: PropTypes.string.isRequired,
  columns: PropTypes.shape({}).isRequired,
  fields: PropTypes.string.isRequired,
  fixedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  providerGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchValue: PropTypes.string,
  showToolbar: PropTypes.bool,
  tableHeight: PropTypes.number,
}

dataGrid.defaultProps = {
  tableHeight: 800,
  searchValue: '',
  showToolbar: false,
}

export default dataGrid
