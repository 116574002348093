import React, { useState } from 'react'
import { Alert, Row } from 'reactstrap'

const ErrorAlert = ({ error }) => {
  const [show, setShow] = useState(true);
  return (
    <>
      <Alert color="danger" onClose={() => setShow(false)} dismissible="true">
        <span className="oi oi-warning" />
        {' '}
        {error}
      </Alert>
    </>
  )
}

export default ErrorAlert
