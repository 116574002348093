import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import ReportButton from '../../utils/ui/report-button'

const toolbarLinkButton = (props) => {
  const { href, icon, id, tooltip} = props
  return (
    <Fragment>
      <ReportButton
        className="btn-light btn-icon toolbar-link"
        href={href}
        icon={icon || "fas fa-download"}
        id={id || "download-csv"}
      />
      <UncontrolledTooltip placement="top" target={id || "download-csv"}>
        {tooltip || "Download CSV"}
      </UncontrolledTooltip>
    </Fragment>
  )
}

toolbarLinkButton.propTypes = {
  href: PropTypes.string.isRequired,
}

export default toolbarLinkButton
