import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import NumberFormat from 'react-number-format'

const NumberFormatter = ({ value }) => (
  <NumberFormat
    value={value}
    displayType="text"
    thousandSeparator
    renderText={value => <span>{value}</span>}
  />
)

const NumberTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={NumberFormatter}
    {...props}
  />
)

export default NumberTypeProvider
