import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';


class ConfirmationModal extends Component {
  confirmClicked = () => {
    const { toggle, onConfirmation } = this.props
    toggle()
    onConfirmation()
  }

  render() {
    const {
      className, cancelButtonText, confirmButtonText, title, body, modalOpen, toggle,
    } = this.props

    return (
      <Modal isOpen={modalOpen} toggle={toggle} className={className}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>{cancelButtonText}</Button>
          {' '}
          <Button color="primary" onClick={this.confirmClicked}>{confirmButtonText}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  cancelButtonText: PropTypes.string,
  className: PropTypes.string,
  confirmButtonText: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  title: PropTypes.string,
  toggle: PropTypes.func.isRequired,
}

ConfirmationModal.defaultProps = {
  cancelButtonText: 'No thanks',
  className: '',
  confirmButtonText: "Yes, I'm sure",
  title: 'Are you sure?',
}

export default ConfirmationModal;
