import React, { Component } from 'react'

function withHeightAdjustment(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        tableHeight: 800, // in pixels
      }
    }

    componentDidMount() {
      window.addEventListener('resize', this.onResize)
      this.onResize()
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize)
    }

    onResize = () => {
      const { tableHeight } = this.state

      const header = document.getElementsByClassName('b-main__header')
      // Need to include the border here. 1 pixel difference makes the resize fail. :(
      const headerHeight = (header && header[0].clientHeight + 1)
      // margin-top of -9rem and margin-bottom of 2rem. That makes 7
      const marginHeight = 7
      // Now calculate rem in pixels
      const offset = Math.floor(marginHeight * parseFloat(getComputedStyle(document.documentElement).fontSize))

      const height = window.innerHeight + offset - headerHeight
      if (height && height !== tableHeight) {
        this.setState({
          tableHeight: height,
        })
      }
    }

    render() {
      const { tableHeight } = this.state
      return (
        <div id="grid-resizer" role="presentation" className="resizer card">
          <WrappedComponent {...this.props} tableHeight={tableHeight} />
        </div>
      )
    }
  }
}

export default withHeightAdjustment
