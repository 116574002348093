import PropTypes from 'prop-types'
import React from 'react'
import startCase from 'lodash.startcase'
import withFieldValidation from '../../components/hoc/with-field-validation'
import withFormRow from '../../components/hoc/with-form-row'
import MozoStyledSelect from './mozo-styled-select'

const endpointInput = (props) => {
  const {
    endpoints, endpoint, onEndpoint, validateHandler,
  } = props

  const onChangeHandler = (event) => {
    const data = event && event.value
    Promise.resolve(onEndpoint(data)).then(() => validateHandler(data))
  }

  const selectOptions = Object.keys(endpoints).map(name => (
    { value: name, label: startCase(name) }
  ))
  const value = selectOptions.filter(option => option.value === endpoint)

  return (
    <MozoStyledSelect
      {...props}
      isClearable
      isSearchable
      onChange={e => onChangeHandler(e)}
      options={selectOptions}
      placeholder="Choose from our Api endpoints to create a report"
      value={value}
    />
  )
}

endpointInput.propTypes = {
  endpoint: PropTypes.string.isRequired,
  endpoints: PropTypes.shape({}).isRequired,
  onEndpoint: PropTypes.func.isRequired,
  validateHandler: PropTypes.func.isRequired,
}

export default withFormRow(withFieldValidation(endpointInput), 'Api endpoint')
