import React from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import NumberFormat from 'react-number-format'

const CurrencyFormatter = ({ value }) => {
  let money
  // 0 = false in JS
  if (value !== null && value !== undefined) {
    const dp = Math.round(value) === value ? 0 : 2
    money = value.toFixed(dp)
  }
  return (
    <NumberFormat
      value={money}
      displayType="text"
      thousandSeparator
      prefix="$"
      renderText={value => <span>{value}</span>}
    />
  )
}

const CurrencyTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={CurrencyFormatter}
    {...props}
  />
)

export default CurrencyTypeProvider
