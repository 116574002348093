import React from 'react'
import startCase from 'lodash.startcase'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const HumaniseFormatter = ({ value }) => startCase(value)

const HumaniseTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={HumaniseFormatter}
    {...props}
  />
)

export default HumaniseTypeProvider
