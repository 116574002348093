import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'

const emptyDataRow = (props) => {
  const { error, errorMessage, loading } = props
  const icon = loading ? 'oi-reload i-spin' : 'oi-warning'
  const cssClasses = `oi ${icon} mr-1`
  const meaningfulErrorMessage = error ? errorMessage : 'Your Report did not return any data'
  const errorColour = error ? 'danger' : 'warning'
  const message = loading ? 'Loading data...' : meaningfulErrorMessage
  const alertColour = loading ? 'success' : errorColour
  return (
    <Alert color={alertColour}>
      <span className={cssClasses} />
      {' '}
      {message}
    </Alert>
  )
}

emptyDataRow.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}

emptyDataRow.defaultProps = {
  loading: false,
  error: false,
  errorMessage: '',
}

export default emptyDataRow
