import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import ReportButton from '../../utils/ui/report-button'

const toggleToolbar = (props) => {
  const { onShowHideChange } = props
  return (
    <Fragment>
      <ReportButton
        className="btn btn-light btn-icon"
        icon="fas fa-table"
        id="show-hide-toolbar"
        onClick={() => onShowHideChange()}
        type="button"
      />
      <UncontrolledTooltip placement="top" target="show-hide-toolbar">
        Pivot table
      </UncontrolledTooltip>
    </Fragment>
  )
}

toggleToolbar.propTypes = {
  onShowHideChange: PropTypes.func.isRequired,
}

export default toggleToolbar
