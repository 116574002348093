import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReportUpdater from '../containers/report-updater'
import AdjustableDataGrid from './adjustable-data-grid'
import withReportReload from '../../components/hoc/with-report-reload'
import withFixedColumns from '../../components/hoc/with-fixed-columns'

class ReportGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
      showToolbar: false,
    }
  }

  componentDidMount() {
    const { reloadData } = this.props
    reloadData();
  }

  onSearchChange = (value) => {
    this.setState({
      searchValue: value,
    })
  }

  onShowHideChange = () => {
    const { showToolbar } = this.state
    this.setState({
      showToolbar: !showToolbar,
    })
  }

  render() {
    const { title } = this.props
    const { searchValue, showToolbar } = this.state

    return (
      <main className="b-main">
        <header className="b-main__header">
          <section className="b-main__header-title-section">
            <h1 className="b-main__header-title">{title}</h1>
          </section>
          <ReportUpdater {...this.props} onSearchChange={this.onSearchChange} onShowHideChange={this.onShowHideChange} />
        </header>
        <div className="b-main__content">
          <div className="b-table">
            <AdjustableDataGrid {...this.props} searchValue={searchValue} showToolbar={showToolbar} />
          </div>
        </div>
      </main>
    )
  }
}

ReportGrid.propTypes = {
  reloadData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default withReportReload(withFixedColumns(ReportGrid))
