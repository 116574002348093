import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
} from 'reactstrap'
import withReportReload from './hoc/with-report-reload'
import filterSummary from '../utils/filter-summary/filter-summary'
import DataGrid from '../data-grid/components/data-grid'

class PrintReportApp extends Component {
  componentDidMount() {
    const { reloadData } = this.props
    reloadData()
  }

  onClick = () => {
    window.print()
  }

  render() {
    const {
      args,
      endpoint,
      title,
    } = this.props

    return (
      <div className="b-printable-report">
        <div className="b-printable-report__header">
          <section className="b-printable-report__header-title-section">
            <h1 className="b-printable-report__header-title">{title}</h1>
            <h6 className="b-printable-report__header-subtitle">
              {filterSummary(args, endpoint)}
            </h6>
          </section>
          <Button
            type="button"
            onClick={() => this.onClick()}
          >
            Print Report
          </Button>
        </div>
        <div className="b-printable-report__inner">
          <DataGrid {...this.props} fixedColumns={[]} tableHeight="100%" />
        </div>
      </div>
    )
  }
}

PrintReportApp.propTypes = {
  args: PropTypes.shape({}).isRequired,
  endpoint: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default withReportReload(PrintReportApp)
