import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { subMonths } from 'date-fns'
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import MozoStyledSelect from '../reports/components/mozo-styled-select'

class HistoryUpdater extends Component {
  constructor(props) {
    super(props)
    const { inputs } = props
    const values = inputs.reduce((obj, input) => ({
      ...obj,
      [input.name]: input.value,
    }), {})

    this.state = {
      filterOpen: false,
      values,
    }
  }

  onChange(e, name) {
    const { values } = this.state
    const obj = { ...values }
    obj[name] = Array.isArray(e) ? e.map((k) => (k.value)) : e && e.value
    this.setState({
      values: obj,
    })
  }

  onSubmit() {
    const { values } = this.state
    const queryString = Object.keys(values).map((k) => (
      `${k}=${values[k] || ''}`
    )).join('&')
    window.location = `${window.location.href.split('?')[0]}?${queryString}`
  }

  textUpdate(e, name) {
    const { value } = e.currentTarget
    const { values } = this.state
    const obj = { ...values }
    obj[name] = value
    this.setState({
      values: obj,
    })
  }

  dateUpdate(e, name) {
    const value = e.toISOString().split('T')[0]
    const { values } = this.state
    const obj = { ...values }
    obj[name] = value
    this.setState({
      values: obj,
    })
  }

  render() {
    const {
      inputs,
      too_many_data_points,
    } = this.props

    const {
      filterOpen,
    } = this.state

    const isExpanded = too_many_data_points || filterOpen ? ' is-expanded' : ''

    const filterToggle = () => {
      this.setState({
        filterOpen: !filterOpen,
      })
    }

    const args = inputs.map((input) => {
      const {
        label,
        name,
        options,
        type,
        value,
      } = input

      const selectOptions = (options || []).map((obj) => (
        {
          key: obj.id,
          label: obj.name,
          name: input.name,
          value: obj.id,
        }
      ))

      const defaultValue = type === 'select' && selectOptions.filter((o) => o.value === value)
      const defaultValues = type === 'multi' && selectOptions.filter((o) => value.includes(o.value))

      const select = type === 'select' && (
        <FormGroup>
          <Label htmlFor={name}>
            {label}
          </Label>
          {' '}
          <MozoStyledSelect
            defaultValue={defaultValue}
            isClearable
            isSearchable
            name={name}
            onChange={(e) => this.onChange(e, name)}
            options={selectOptions}
            placeholder="Optional. Select one..."
          />
        </FormGroup>
      )

      const multi = type === 'multi' && (
        <FormGroup>
          <Label htmlFor={name}>
            {label}
          </Label>
          {' '}
          <MozoStyledSelect
            closeMenuOnSelect={false}
            defaultValue={defaultValues}
            isClearable
            isMulti
            isSearchable
            name={name}
            onChange={(e) => this.onChange(e, name)}
            options={selectOptions}
            placeholder="Optional. Select 1 or more..."
          />
        </FormGroup>
      )

      const text = type === 'text' && (
        <FormGroup>
          <Label htmlFor={name}>
            {label}
          </Label>
          {' '}
          <Input
            type="text"
            name={name}
            defaultValue={value}
            placeholder="Optional. Enter your value..."
            onChange={(e) => this.textUpdate(e, name)}
          />
        </FormGroup>
      )

      const { values: { start_date, end_date }} = this.state
      const selectedDate = name === 'start_date' ? start_date : end_date

      const date = type === 'date' && (
        <FormGroup>
          <Label htmlFor={name}>
            {label}
          </Label>
          {' '}
          <DatePicker
            selected={new Date(selectedDate)}
            name={name}
            onChange={e => this.dateUpdate(e, name)}
            dateFormat="yyyy-MM"
            minDate={new Date('2011/01/01')}
            maxDate={subMonths(new Date(), 0)}
            showMonthYearPicker
          />
        </FormGroup>
      )

      return (
        <Col sm={3}>
          {select}
          {multi}
          {text}
          {date}
        </Col>
      )
    })

    return (
      <div className="b-toolbar">
        <div className="b-toolbar__actions">
          <Button
            className="btn-light btn-icon mr-0 toolbar-link"
            id="download-csv"
            onClick={window.highchartsDownloadCsv}
            type="button"
          >
            <span className="fas fa-download" />
          </Button>
          <UncontrolledTooltip placement="top" target="download-csv">
            Download CSV
          </UncontrolledTooltip>
        </div>
        <div id="report-filter" className={`b-report-filter${isExpanded}`}>
          <button
            type="button"
            id="report-filter-toggle"
            className="btn btn-light b-report-filter__toggle"
            onClick={() => filterToggle()}
          >
            <span className="fa fa-sliders-h" />
            {' '}
            Filters
          </button>
          <div className="b-report-filter__dropdown">
            <Row>
              {args}
            </Row>
            <Row className="align-middle">
              <Col sm={3}>
                <Button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={() => this.onSubmit()}
                >
                  Refresh
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

HistoryUpdater.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.shape({})),
  too_many_data_points: PropTypes.bool.isRequired,
}

HistoryUpdater.defaultProps = {
  inputs: null,
}

export default HistoryUpdater
