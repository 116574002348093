const type = args => (
  {
    STANDARD: 'STANDARD',
    CASHMANAGEMENT: 'CASH MGNT',
}[args.type]
)

const customerType = args => (args.customerType)

const rateType = args => (
  {
    BASEONLY: 'BASE',
    INTRO: 'INTRO',
    ONGOING: 'ONGOING',
  }[args.rateType]
)

const accountBalance = args => (args.accountBalance && `$${parseInt(args.accountBalance / 1000)}k`)


const savingsAccountFilterSummary = args => (
  [
    type(args),
    customerType(args),
    rateType(args),
    accountBalance(args),
  ].filter(Boolean).join(' | ')
)

export default savingsAccountFilterSummary
