import PropTypes from 'prop-types'
import React, { useState } from 'react'
import startCase from 'lodash.startcase'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { components } from 'react-select'
import MozoStyledSelect from './mozo-styled-select'
import withFormRow from '../../components/hoc/with-form-row'
import withFieldValidation from '../../components/hoc/with-field-validation'

const arrayMove = (array, from, to) => {
  array = array.slice()
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
  return array
}

const SortableMultiValue = SortableElement(props => {
  const onMouseDown = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const innerProps = { onMouseDown }
  return <components.MultiValue {...props} innerProps={innerProps} />
})

const SortableSelect = SortableContainer(MozoStyledSelect)

const fieldLabel = (name) => {
  var label = startCase(name)
  return label == 'Chess Ownership Available' ? 'CHESS Ownership Available' : label
}

const fieldsInput = (props) => {
  const {
    endpoints, endpoint, fields, onFieldsChange, validateHandler,
  } = props

  const [sortedValue, setSortedValue] = useState([])

  const onChangeHandler = (event) => {
    const data = event.map(option => option.value)
    Promise.resolve(onFieldsChange(data)).then(() => validateHandler(data))
  }
  const selectOptions = endpoints[endpoint] && endpoints[endpoint].fields.map(name => ({ value: name, label: fieldLabel(name) }))
  const selected = selectOptions ? fields.map(name => selectOptions.find(object => object.value === name)) : []

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex)
    const mappedNewValue = newValue.map(i => i.value)
    Promise.resolve(onFieldsChange(mappedNewValue)).then(() => validateHandler(mappedNewValue))
    setSortedValue([])
  }

  return (
    <SortableSelect
      {...props}
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isClearable
      closeMenuOnSelect={false}
      isMulti
      isSearchable
      onChange={e => onChangeHandler(e)}
      options={selectOptions}
      placeholder="Choose the columns to include in your report in order from left to right"
      value={selected}
      components={{
        MultiValue: SortableMultiValue,
      }}
    />
  )
}

fieldsInput.propTypes = {
  endpoint: PropTypes.string.isRequired,
  endpoints: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  validateHandler: PropTypes.func.isRequired,
}

export default withFormRow(withFieldValidation(fieldsInput, 'Please choose at least 1 column'), 'Columns')
