import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import ReportButton from '../../utils/ui/report-button'
import ConfirmationModal from '../components/confirmation-modal'
import AlertModal from '../components/alert-modal'
import { withToggle } from '../../components/hoc/index'

class DeleteReportButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
    }
  }

  showConfirmation = () => {
    const { toggle } = this.props
    this.setState({
      errors: [],
    })
    toggle()
  }

  deleteReport = () => {
    const { id, toggle } = this.props
    axios.delete(`${window.location}/${id}`)
      .then((response) => {
        if (response.data.success) {
          // TODO: It would be nicer just to remove the grid row - but I can't do that without a plugin
          window.location.reload()
        } else {
          this.setState({
            errors: response.data.errors,
          })
          toggle()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  render() {
    const { name } = this.props
    const { errors } = this.state
    const title = `Delete '${name}'?`
    const body = `This will remove the report '${name}' completely. You cannot reverse this decision. Are you sure you want to delete this report?`
    const showErrors = errors && errors.length > 0

    const confirm = !showErrors && (
      <ConfirmationModal
        {...this.props}
        onConfirmation={this.deleteReport}
        title={title}
        body={body}
        cancelButtonText="Cancel"
        confirmButtonText="Yes, delete this report"
      />
    )

    const alert = showErrors && (
      <AlertModal
        {...this.props}
        body={errors[0]}
      />
    )

    return (
      <Fragment>
        <ReportButton
          color="danger"
          title="Delete the report"
          icon="oi oi-trash"
          onClick={this.showConfirmation}
        />
        {confirm}
        {alert}
      </Fragment>
    )
  }
}

DeleteReportButton.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}

export default withToggle(DeleteReportButton)
