import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'
import ReportButton from '../../utils/ui/report-button'

const printButton = (props) => {
  const { printURL } = props
  const url = printURL()

  return (
    <Fragment>
      <ReportButton
        className="btn-light btn-icon toolbar-link"
        href={url}
        icon="fas fa-print"
        id="print-report"
        target="_blank"
      />
      <UncontrolledTooltip placement="top" target="print-report">
        Print Preview
      </UncontrolledTooltip>
    </Fragment>
  )
}

printButton.propTypes = {
  printURL: PropTypes.func.isRequired,
}

export default printButton
