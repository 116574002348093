import React, { Component } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import queryBuilder from '../../utils/query-builder'
import loadingTimer from '../../utils/loading-timer'

function withReportReload(WrappedComponent) {
  return class extends Component {
    static propTypes = {
      args: PropTypes.shape({}).isRequired,
      csvUrl: PropTypes.string.isRequired,
      csvUrlWithNumeric: PropTypes.string.isRequired,
      endpoint: PropTypes.string.isRequired,
      fields: PropTypes.string.isRequired,
      graphqlEndpoint: PropTypes.string.isRequired,
      inputs: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
    }

    constructor(props) {
      super(props)
      const { args, csvUrl, csvUrlWithNumeric } = props
      this.state = {
        args,
        csvUrl,
        csvUrlWithNumeric,
        error: false,
        errorMessage: '',
        loading: true,
        rows: [],
      }
    }

    setReportArguments = (newInputs) => {
      // It is possible to have query arguments that are NOT an input,
      // and it is possible to have inputs that are NOT a query argument
      // In the case where an argument is _also_ an input, the input governs it's value.
      // As such, remove all input keys from the original args value in state and then re-assign from the UI
      // (Remember, it is possible for an input to be nil in the UI - which means it should NOT be part of the query.
      // This is why we need to delete the keys)
      const { args } = this.state
      const { inputs } = this.props
      const object = Object.assign({}, args)
      inputs.forEach(input => delete object[input.name])
      this.setState({ args: Object.assign(object, newInputs) })
    }

    reloadData = () => {
      Promise.resolve(this.setState({ rows: [], loading: true }))
        .then(loadingTimer(750).then(() => this.fetchData()))
    }

    printUrl = () => {
      const { endpoint, fields } = this.props
      const data = {
        operationName: null,
        variables: null,
        query: queryBuilder({ ...this.state, endpoint, fields }),
      }
      const queryString = Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&')
      return `${window.location.pathname}/print?${queryString}`
    }

    fetchData = () => {
      const { graphqlEndpoint, endpoint, fields } = this.props
      const data = {
        operationName: null,
        variables: {},
        query: queryBuilder({ ...this.state, endpoint, fields }),
      }
      axios.post(graphqlEndpoint, data)
        .then((response) => {
          if (response.data.data) {
            return this.setState({
              csvUrl: response.data.data.csvUrl,
              csvUrlWithNumeric: response.data.data.csvUrlWithNumeric,
              error: false,
              loading: false,
              rows: response.data.data[endpoint],
            })
          }

          throw new Error(response.data.errors[0].messages)
        })
        .catch((error) => {
          const { csvUrl, csvUrlWithNumeric } = this.props

          this.setState({
            csvUrl,
            csvUrlWithNumeric,
            error: true,
            errorMessage: error.message,
            loading: false,
            rows: [],
          })
        })
    }

    render() {
      const {
        args, csvUrl, csvUrlWithNumeric, error, errorMessage, loading, rows,
      } = this.state

      return (
        <WrappedComponent
          {...this.props}
          args={args}
          csvUrl={csvUrl}
          csvUrlWithNumeric={csvUrlWithNumeric}
          error={error}
          errorMessage={errorMessage}
          loading={loading}
          printURL={this.printUrl}
          reloadData={this.reloadData}
          rows={rows}
          setReportArguments={this.setReportArguments}
        />
      )
    }
  }
}

export default withReportReload
