import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import queryBuilder from '../../utils/query-builder'

function withReportEdit(WrappedComponent) {
  return class extends Component {
    static propTypes = {
      endpoint: PropTypes.string.isRequired,
      args: PropTypes.shape({}).isRequired,
      fields: PropTypes.arrayOf(PropTypes.string).isRequired,
      graphqlEndpoint: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      inputs: PropTypes.arrayOf(PropTypes.string).isRequired,
      locked: PropTypes.arrayOf(PropTypes.string).isRequired,
      name: PropTypes.string.isRequired,
      shared: PropTypes.bool.isRequired,
    }

    constructor(props) {
      super(props);

      const valid = this.isValid(props)

      const {
        args, endpoint, fields, inputs, locked, name, shared,
      } = props

      this.state = {
        args, endpoint, fields, inputs, locked, name, shared, valid, saved: '', error: '',
      }
    }

    isValid = (object) => {
      const { endpoint, fields, name } = object
      return endpoint !== '' && fields.length > 0 && name !== ''
    }

    onValueInput = (event) => {
      const { name, value } = event.target
      this.onChange(name, value)
    }

    onEndpoint = (value) => {
      this.onChange('endpoint', value)
      this.setState({
        args: {},
        endpoint: value || null,
        fields: ['providerLogo', 'providerName', 'name'],
        inputs: [],
        locked: [],
        saved: '',
        valid: false,
      })
    }

    onFieldsChange = (value) => {
      if (value.length > 0) {
        this.onChange('fields', value)
      } else {
        this.setState({
          fields: [],
          locked: [],
          saved: '',
          valid: false,
        })
      }
    }

    onChange = (name, value) => {
      const validTest = Object.assign({}, this.state)
      const valid = this.isValid(Object.assign(validTest, { [`${name}`]: value }))
      this.setState({ [`${name}`]: value, saved: '', valid })
    }

    onInputShown = (event) => {
      const { checked, name } = event.currentTarget
      const { inputs } = this.state
      const newInputs = checked ? [...inputs, name] : inputs.filter(i => i !== name)
      this.setState({
        inputs: newInputs,
      })
    }

    onCheckBox = (event) => {
      const { name, checked } = event.target
      this.onChange(name, checked)
    }

    onArgumentInput = (name, data) => {
      const { args } = this.state
      const object = Object.assign({}, args)

      if (Array.isArray(data)) { // multi select. get values correctly
        if (data.length > 0) {
          object[name] = data.map(entry => (entry.value))
        } else {
          delete object[name]
        }
      } else {
        const value = data && data.value
        if (value) {
          object[name] = value
        } else {
          delete object[name]
        }
      }
      this.onChange('args', object)
    }

    onSaveReport = () => {
      const {
        args, endpoint, fields, inputs, locked, name, shared,
      } = this.state

      const { id, graphqlEndpoint } = this.props
      const mutationName = id ? 'editReport' : 'createReport'
      const argInputs = inputs.map(i => `"${i}"`).join(', ')
      const lockedColumns = locked.map(i => `"${i}"`).join(', ')
      const mutation = `mutation { ${mutationName}(${id ? `id: ${id}` : ''} name: "${name}", query: "${queryBuilder({ endpoint, args, fields })}", shared: ${shared}, argumentInputs: [${argInputs}], lockedColumns: [${lockedColumns}]) { id errors }}`

      const data = {
        query: mutation,
        variables: null,
        token: 'my_token', // TODO: This has to come from props! .. maybe. Not sure if we need this atm
      }

      axios.post(graphqlEndpoint, data)
        .then((response) => {
          const result = response.data.data[mutationName]
          if (result.errors.length > 0) {
            this.setState({ error: `Error! - (${result.errors.join(' ')})` })
          } else {
            const reportPath = id ? `/reports/${id}` : '/reports'
            const path = window.location.href.includes('/admin/') ? '/admin/reports' : reportPath
            window.location.href = path
          }
        })
        .catch(() => {
          // this.errorPopup(['Sorry but there was an error processing your request. Please refresh the page and try again'])
        })
    }

    render() {
      const { valid } = this.state

      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          valid={valid}
          onArgumentInput={this.onArgumentInput}
          onChange={this.onChange}
          onCheckBox={this.onCheckBox}
          onEndpoint={this.onEndpoint}
          onFieldsChange={this.onFieldsChange}
          onInputShown={this.onInputShown}
          onPreviewReport={this.onPreviewReport}
          onSaveReport={this.onSaveReport}
          onValueInput={this.onValueInput}
        />
      )
    }
  }
}

export default withReportEdit
