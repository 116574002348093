import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import { toQueryString, checkStatus } from '../utils/fetch-functions'

function FileUpload(props) {
  const [okToDrop, setOkToDrop] = useState(true)
  const [publicUrl, setPublicUrl] = useState('')

  const { updateGuideList } = props

  const uploadFile = (file, signedUrl, publicUrlPath) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      const arrayStr = reader.result

      axios({
        method: 'PUT',
        url: signedUrl,
        data: arrayStr,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Content-Type': 'application/octet-stream',
        },
      })
      .then(() => {
        axios({
          method: 'POST',
          url: '/admin/user_guides',
          data: { path: publicUrlPath },
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          setOkToDrop(true)
          updateGuideList({
            path: response.data.result.path,
            uploadedAt: response.data.result.updated_at,
          })
        })
        .catch((error) => console.log(error))
      })
      .catch((error) => console.log(error))
    }
  }

  const onDrop = (files) => {
    setOkToDrop(false)

    const file = files[0]
    const filename = file.name.replace(/[^a-zA-Z\d-.]/g, '-').toLowerCase()
    let dateString = new Date()
    const dd = String(dateString.getDate()).padStart(2, '0')
    const mm = String(dateString.getMonth() + 1).padStart(2, '0')
    const yyyy = dateString.getFullYear()
    dateString = yyyy + mm + dd

    const path = `marketview3/user-guides/${dateString}-${filename}`

    axios({
      method: 'GET',
      url: '/admin/user_guides/get_signed_url?' + toQueryString({ path }),
      data: file,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(checkStatus)
    .then((response) => {
      setPublicUrl(response.data.public_url)
      uploadFile(file, response.data.signed_url, response.data.public_url)
    })
  }

  const selectFile = publicUrl === '' ? (<p className="text-center">Drop PDF here or <br />click to select</p>) : (<p className="text-center">Upload Complete.<br /><br />Drop PDF here or <br />click to select</p>)
  const fileUploading = (<p className="text-center">Uploading file...</p>)
  const fileStatus = okToDrop ? selectFile : fileUploading

  return (
    <Dropzone
      accept="application/pdf"
      className="dropzone"
      disableClick={!okToDrop}
      multiple={false}
      onDrop={onDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className="dropzone-outer-box">
          <input {...getInputProps()} />
          <div className="dropzone dropzone-text pad--1 grid grid-bleed align-end">
            {fileStatus}
          </div>
        </div>
      )}
    </Dropzone>
  )
}

FileUpload.propTypes = {
  updateGuideList: PropTypes.func.isRequired,
}

export default FileUpload
