import PropTypes from 'prop-types'
import React from 'react'
import { Alert, Col, FormGroup } from 'reactstrap'

const flashBar = (props) => {
  const { error, saved } = props

  const errorAlert = error && (<Alert color="danger">{error}</Alert>)
  const savedAlert = saved && (<Alert color="success">{saved}</Alert>)
  return (
    <FormGroup row>
      <Col sm={12}>
        {savedAlert}
        {errorAlert}
      </Col>
    </FormGroup>
  )
}

flashBar.propTypes = {
  error: PropTypes.string,
  saved: PropTypes.string,
}

flashBar.defaultProps = {
  saved: '',
  error: '',
}

export default flashBar
