import React from 'react'
import { 
  Button, 
  Form,
  FormGroup,
  Input, 
  Label, 
} from 'reactstrap'
import 'react-datepicker/dist/react-datepicker.css'
import ScheduleSelect from './components/schedule-select'

const ReportEmailScheduleForm = (props) => {
  const {
    handleCreateNewSchedule,
    handleScheduleChange,
    selectedSchedule,
    scheduleList,
  } = props

  return (
    <Form className="b-create-report__form">
      <FormGroup>
        <Label for="frequency">Select Existing Report *</Label>
        <ScheduleSelect
          id="frequency"
          scheduleList={scheduleList}
          selectedSchedule={selectedSchedule}
          handleChange={handleScheduleChange}
        />
      </FormGroup>
      <Button type="button" color="primary" onClick={handleCreateNewSchedule} >Create New Email Schedule</Button>
    </Form>
  )
}


export default ReportEmailScheduleForm
