import React, { Component } from 'react'

function withFixedColumns(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        fixedColumns: props.fixedColumns,
      };
    }

    onSelection = (event) => {
      this.setState({
        fixedColumns: event.map(data => data.value),
      })
    }

    render() {
      const { fixedColumns } = this.state
      return (
        <WrappedComponent {...this.props} onSelection={this.onSelection} fixedColumns={fixedColumns} />
      )
    }
  }
}

export default withFixedColumns
