import React from 'react'
import PropTypes from 'prop-types'
import ReportForm from './report-form'

const reportCreator = (props) => {
  const { id } = props
  const title = id ? 'Edit Report' : 'Create new report'
  return (
    <main className="b-main">
      <header className="b-main__header">
        <section className="b-main__header-title-section">
          <h1 className="b-main__header-title">{title}</h1>
        </section>
      </header>
      <div className="b-main__content">
        <div className="b-main__content-inner">
          <ReportForm {...props} />
        </div>
      </div>
    </main>
  )
}

reportCreator.propTypes = {
  id: PropTypes.number,
}

reportCreator.defaultProps = {
  id: null,
}

export default reportCreator
