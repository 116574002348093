import * as React from 'react';
import * as PropTypes from 'prop-types';

export class HomeLoanInput extends React.PureComponent {
  render() {
    const { endpoint } = this.props

    return endpoint === 'homeLoans' && (
      <React.Fragment>
        <label htmlFor="homeLoanAmount">Loan Amount</label>
        <input
          id="homeLoanAmount"
          type="text"
          className="form-control w-25"
          onInput={e => onValueChange(e.target.value)}
          value={value}
          placeholder="Enter loan amount..."
        />
      </React.Fragment>
    )
  }
}

HomeLoanInput.propTypes = {
  endpoint: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
}
