export const checkStatus = (response) => {
	if (response.status >= 200 && response.status < 300) {
		return response
	}

	const error = new Error(response.statusText)
	error.response = response
	throw error
}

export const toQueryString = (obj) => {
	const parts = []
	for (let i in obj) {
		parts.push(`${encodeURIComponent(i)}=${encodeURIComponent(obj[i])}`)
	}
	return parts.join('&')
}
