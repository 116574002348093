import React, { useState } from 'react'
import { startCase } from 'lodash'
import SelectInput from './select-input';

const FrequencySelect = (props) => {
  const { scheduleList, selectedSchedule, handleChange } = props

  const value = scheduleList.find(item => item.value === selectedSchedule)

  return (
    <SelectInput
			value={value}
      onChange={handleChange}
      options={scheduleList}
      isSearchable
			name="frequency"
      placeholder="Select 1 option"
    />
  )
}

export default FrequencySelect
