import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

const reportButton = (props) => {
  const { icon } = props

  return (
    <Button
      {...props}
    >
      <span className={icon} />
    </Button>
  )
}

reportButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
}

reportButton.defaultProps = {
  href: '',
}

export default reportButton
