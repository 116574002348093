import React from 'react'
import PropTypes from 'prop-types'
import { CustomInput } from 'reactstrap'
import withFormRow from '../../components/hoc/with-form-row'

const showInReport = (props) => {
  const { name, onInputShown, show } = props
  return (
    <CustomInput
      type="switch"
      id={name}
      className="b-create-report__filter-switch"
      name={name}
      label="Show in report"
      checked={show}
      onChange={e => onInputShown(e)}
    />
  )
}

showInReport.propTypes = {
  name: PropTypes.string.isRequired,
  onInputShown: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default withFormRow(showInReport)
