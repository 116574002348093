import React, { Fragment } from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import ReportButton from '../../utils/ui/report-button'
import CopyReportButton from '../containers/copy-report-button'
import DeleteReportButton from '../containers/delete-report-button'

const buttonFormatter = ({ row }) => {
  const showButton = (
    <ReportButton
      href={`${window.location}/${row.id}`}
      title="View the report"
      icon="oi oi-eye"
    />
  )

  const editButton = row.can_edit && (
    <ReportButton
      color="success"
      href={`${window.location}/${row.id}/edit`}
      title="Edit the report"
      icon="oi oi-pencil"
    />
  )

  const deleteButton = row.can_edit && (<DeleteReportButton id={row.id} name={row.name} />)
  const copyButton = <CopyReportButton id={row.id} name={row.name} endpoint={row.endpoint} />

  return (
    <Fragment>
      {showButton}
      {' '}
      {editButton}
      {' '}
      {copyButton}
      {' '}
      {deleteButton}
    </Fragment>
  )
}

const ActionsTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={buttonFormatter}
    {...props}
  />
)

export default ActionsTypeProvider
