import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'
import MozoStyledSelect from '../../reports/components/mozo-styled-select'

const fixedColumnModal = (props) => {
  const {
    modalOpen, toggle, fields, columns, fixedColumns, onSelection,
  } = props

  const selectOptions = fields.split(' ').map(name => (
    { value: name, label: columns[name].title }
  ))

  const columnsAlreadyFixed = fixedColumns.map(name => (
    { value: name, label: columns[name].title }
  ))

  return (
    <Modal isOpen={modalOpen} toggle={toggle}>
      <ModalHeader>Locked data columns</ModalHeader>
      <ModalBody>
        <MozoStyledSelect
          defaultValue={columnsAlreadyFixed}
          options={selectOptions}
          placeholder="Select the columns you would like to lock in place"
          isSearchable
          isClearable
          isMulti
          onChange={e => onSelection(e)}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>OK</Button>
      </ModalFooter>
    </Modal>
  )
}

fixedColumnModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  fields: PropTypes.string.isRequired,
  columns: PropTypes.shape({}).isRequired,
  fixedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelection: PropTypes.func.isRequired,
}

export default fixedColumnModal
