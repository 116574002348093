const loanType = args => (
  {
    LOAN: 'LOAN',
    LINEOFCREDIT: 'LOC',
  }[args.loanType]
)

const rateType = args => (args.rateType)

const loanPurpose = args => (
  {
    CARONLY: 'CAR',
    MULTIPURPOSE: 'MULTI',
    OTHER: 'OTHER',
  }[args.loanPurpose]
)

const carType = args => (args.carType)

const loanKind = args => (args.loanKind)

const securityType = args => (args.securityType)

const loanAmount = args => (args.loanAmount && `$${parseInt(args.loanAmount / 1000)}k`)

const loanTerm = args => (args.loanTerm && (args.loanTerm === 1 ? '1 YEAR' : `${args.loanTerm} YEARS`))

const personalLoanFilterSummary = args => (
  [
    loanType(args),
    rateType(args),
    loanPurpose(args),
    carType(args),
    loanKind(args),
    securityType(args),
    loanAmount(args),
    loanTerm(args),
  ].filter(Boolean).join(' | ')
)

export default personalLoanFilterSummary
