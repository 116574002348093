import React, { Fragment } from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'

const WrappedFormatter = ({ value }) => {
  const overriddenStyles = { whiteSpace: 'pre-wrap' }
  return (<div style={overriddenStyles}>{value}</div>)
}

const WrappedTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={WrappedFormatter}
    {...props}
  />
)

export default WrappedTypeProvider
