const borrowerType = args => (args.borrowerType)

const homeLoanCategory = args => (
  {
    FIXED: 'FIXED',
    FIXEDSTANDARD: 'FIXED STD',
    FIXEDPACKAGE: 'FIXED PKG',
    VARIABLE: 'VARIABLE',
    VARIABLEBASIC: 'VARIABLE BASIC',
    VARIABLEINTRO: 'INTRO',
    VARIABLELINEOFCREDIT: 'LINE OF CREDIT',
    VARIABLEOFFSET: 'OFFSET',
    VARIABLEPACKAGE: 'VARIABLE PKG',
    VARIABLESTANDARD: 'STANDARD',
  }[args.homeLoanCategory]
)

const loanAmount = args => (args.loanAmount && `$${parseInt(args.loanAmount / 1000)}k`)

const repaymentType = args => (
  args.repaymentType && args.repaymentType === 'PRINCIPALANDINTEREST' ? 'P & I' : 'IO'
)

const loanValueRatio = args => (
  args.loanValueRatio && `LVR${args.loanValueRatio}`
)


const homeLoanFilterSummary = (args) => (
  [
    homeLoanCategory(args),
    loanAmount(args),
    borrowerType(args),
    repaymentType(args),
    loanValueRatio(args),
  ].filter(Boolean).join(' | ')
)

export default homeLoanFilterSummary
