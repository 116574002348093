import React from 'react'
import PropTypes from 'prop-types'
import { CustomInput } from 'reactstrap'
import withFormRow from '../../components/hoc/with-form-row'

const sharedInput = (props) => {
  const { onCheckBox, shared } = props
  return (
    <CustomInput
      type="switch"
      id="shared"
      name="shared"
      label="Share this report with others in my subscription"
      checked={shared}
      onChange={e => onCheckBox(e)}
    />
  )
}

sharedInput.propTypes = {
  onCheckBox: PropTypes.func.isRequired,
  shared: PropTypes.bool.isRequired,
}

export default withFormRow(sharedInput)
